import React from "react"

import CandidateSearchPage from "../../pages-sections/search"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import BreadcrumbComponent from "../../components/breadcrumb/breadcrum"

export default function SearchCandidate({ location }) {
  return (
    <Layout noVerification={true}>
      <SEO
        title={"Search Candidates - WDN"}
        description={
          "WDN gives you an opportunity to freely search among thousands of candidates in WDN and find the perfect fit for your company."
        }
        seoImage={"./images/wdn-search-candidates.jpeg"}
      />
      <BreadcrumbComponent
        pages={[{ name: "Home", link: "/" }]}
        currentPage={{
          name: `Search Candidates`,
          link: "/",
        }}
      />
      <CandidateSearchPage location={location} />
    </Layout>
  )
}
